<template>
  <div class="component">
    <van-action-sheet v-model="show" title="设置重复">
      <div class="content" style="color: #818181;">
        <van-radio-group @change="customDate = []" icon-size="15" v-model="radio">
          <van-cell-group>
            <van-cell title="每日" clickable @click="radio = '1'">
              <template #right-icon>
                <van-radio name="1"/>
              </template>
            </van-cell>
            <van-cell title="每周" clickable @click="radio = '2'">
              <template #right-icon>
                <van-radio name="2"/>
              </template>
            </van-cell>
            <van-cell title="每月" clickable @click="radio = '3'">
              <template #right-icon>
                <van-radio name="3"/>
              </template>
            </van-cell>
            <van-cell title="自定义重复" @click="show = false,custom = true,radio = ''" is-link>
              <div v-if="customDate.rateType == 1">
                每{{ customDate.rateNum }}日重复
              </div>
              <div v-if="customDate.rateType == 2">
                每{{ customDate.rateNum }}周，{{ String(customDate.dayNumName) }}重复
              </div>
              <div v-if="customDate.rateType == 3">
                每{{ customDate.rateNum }}月{{ String(customDate.dayNumName) }}重复
              </div>
            </van-cell>
          </van-cell-group>
          <div class="algin">
            <div class="header" style="color: #323233">
              重复方式
            </div>
            <div class="options">
              <div :class="{'active-btn':optionSwitch == 0 }" @click="optionSwitch = 0">一直重复</div>
              <div :class="{'active-btn':optionSwitch == 1 }" @click="optionSwitch = 1">重复次数</div>
              <div :class="{'active-btn':optionSwitch == 2 }" @click="optionSwitch = 2">结束重复时间</div>
            </div>
            <van-divider/>
            <div style="height: 50px;">
              <div v-if="optionSwitch == 1"
                   style="display: flex;align-items: center;justify-content: space-between;color: rgba(0,0,0,.45)">
                <van-stepper max="100" v-model="repeatValue"/>
                <span>日程将重复{{ repeatValue }}次后结束</span>
              </div>
              <div v-if="optionSwitch == 2" style="border: 1.2px solid #e6e6e7">
                <van-field
                    disabled
                    @click="$refs.time.$show()"
                    border
                    v-model="repeatTime"
                    right-icon="notes-o"
                    placeholder="请选择结束时间"
                />
              </div>
            </div>
          </div>
        </van-radio-group>
        <div style="display: flex;align-items: center;justify-content: space-between;padding: 10px">
          <van-button @click="show = false" style="width: 45%;" type="default">取消</van-button>
          <van-button @click="timeChose" block style="width: 45%;" type="info">确定
          </van-button>
        </div>

      </div>
    </van-action-sheet>
    <addTime ref="time" @change="overTime"/>

    <van-action-sheet v-model="custom" title="自定义重复">
      <div class="content" style="color: #818181;">
        <van-cell :title="customValue" style="padding: 22px 16px;font-weight: bolder;font-size: 15px" value=""/>
        <van-cell title="频率" value="">
          <div class="frequency">
            <span :class="{active:switchDate == 0}" @click="switchDate = 0,switchText = '日'">日</span>
            <span :class="{active:switchDate == 1}" @click="switchDate = 1,switchText = '周'">周</span>
            <span :class="{active:switchDate == 2}" @click="switchDate = 2,switchText = '月'">月</span>
          </div>
        </van-cell>
        <van-cell title="每" value="">
          <div style="display: flex;align-items: center;float: right;">
            <van-stepper max="100" v-model="switchValue" style="margin-right: 5px;"/>
            {{ switchText }}
          </div>
        </van-cell>
        <div v-if="switchDate == 0" style="background: #f8f8f8;width: 100%;padding: 5px 12px; color:rgba(0,0,0,.45)">
          日程将于每{{ switchValue }}{{ switchText }}重复
        </div>
        <div v-if="switchDate == 1" style="background: #f8f8f8;width: 100%;padding: 5px 12px; color:rgba(0,0,0,.45)">
          日程将于每{{ switchValue }}{{ switchText }},{{ String(weekNames) }}重复
        </div>
        <div v-if="switchDate == 2" style="background: #f8f8f8;width: 100%;padding: 5px 12px; color:rgba(0,0,0,.45)">
          日程将于每{{ switchValue }}{{ switchText }},{{ String(choseTimeName) }}重复
        </div>
        <div style="height: 200px;overflow:auto;">
          <!--          <van-cell-group v-if="switchDate == 1">-->
          <!--            <van-cell title="星期一"/>-->
          <!--            <van-cell title="星期二"/>-->
          <!--            <van-cell title="星期三"/>-->
          <!--            <van-cell title="星期四"/>-->
          <!--            <van-cell title="星期五"/>-->
          <!--            <van-cell title="星期六"/>-->
          <!--            <van-cell title="星期日"/>-->
          <!--          </van-cell-group>-->
          <van-checkbox-group @change="weekName" v-if="switchDate == 1" v-model="weekResult">
            <van-cell-group>
              <van-cell
                  v-for="(item, index) in weekList"
                  clickable
                  :key="index"
                  :title="item"
              >
                <template #right-icon>
                  <van-checkbox :name="index" ref="checkboxes"/>
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>

          <div v-if="switchDate == 2" class="rDatae">
            <div @click="choseDate(i)" :class="{active: choseTime.indexOf(i) != -1}" v-for="i in 31" :key="i">
              {{ i }}
            </div>
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;padding:5px 10px">
          <van-button style="width: 45%;" @click="previousStep" type="default">上一步</van-button>
          <van-button @click="customDateChose" style="width: 45%;" type="info">保存</van-button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import addTime from "./addTime";

export default {
  components: {
    addTime
  },
  data() {
    return {
      show: false,
      radio: '1',
      // 重复选项切换
      optionSwitch: 0,
      // 次数
      repeatValue: 1,
      // 重复结束时间
      repeatTime: '',
      // 自定义重复
      custom: false,
      customValue: "每1个月重复",
      weekResult: [],
      weekList: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      weekNames: [],
      choseTimeName: [],
      // 结束时间
      overDate: '',
      // 切换
      switchDate: 0,
      switchText: '日',
      switchValue: 1,
      choseTime: [],
      customDate: '',
    }
  },
  watch: {},
  methods: {
    $show() {
      this.show = true
    },
    choseDate(i) {
      if (this.choseTime.indexOf(i) != -1) {
        const index = this.choseTime.indexOf(i)
        if (index == 0) {
          this.choseTime.splice(index, 1)
        } else {
          this.choseTime.splice(index, 1)
        }
      } else {
        this.choseTime.push(i)
      }
      this.choseTimeName = this.choseTime.map(item => {
        return item + "号"
      })
    },
    overTime(e) {
      this.overDate = e
      this.repeatTime = e.date + " " + e.time
    },
    previousStep() {
      this.custom = false
      this.choseTime = []
      this.switchValue = 1
      this.weekResult = []
      this.show = true
    },
    weekName() {
      const weekName = []
      for (let i = 0; i < this.weekResult.length; i++) {
        switch (this.weekResult[i]) {
          case 0:
            weekName.push("星期天")
            break
          case 1:
            weekName.push("星期一")
            break
          case 2:
            weekName.push("星期二")
            break
          case 3:
            weekName.push("星期三")
            break
          case 4:
            weekName.push("星期四")
            break
          case 5:
            weekName.push("星期五")
            break
          case 6:
            weekName.push("星期六")
            break
        }
      }
      this.weekNames = weekName
    },
    customDateChose() {
      const customDate = {}
      switch (this.switchDate) {
        case 0:
          customDate.repeatType = 4
          customDate.rateType = 1
          customDate.rateNum = this.switchValue
          customDate.name = "自定义重复"
          customDate.title = '日程将于每' + this.switchValue + this.switchText + "重复"

          break;
        case 1:
          customDate.repeatType = 4
          customDate.rateType = 2
          customDate.rateNum = this.switchValue
          customDate.dayNum = this.weekResult
          customDate.dayNumName = this.weekNames
          customDate.name = "自定义重复"
          customDate.title = '日程将于每' + this.switchValue + this.switchText + String(this.weekNames) + "重复"

          break;
        case 2:
          customDate.repeatType = 4
          customDate.rateType = 3
          customDate.rateNum = this.switchValue
          customDate.dayNum = this.choseTime
          customDate.dayNumName = this.choseTimeName
          customDate.name = "自定义重复"
          customDate.title = '日程将于每' + this.switchValue + this.switchText + String(this.choseTimeName) + "重复"

          break;
      }
      this.customDate = customDate
      this.custom = false
      this.show = true
    },
    timeChose() {
      const data = {
        repeat: {},
        endRepeat: {}
      }
      if (this.radio) {
        const dd = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
        const now = new Date();
        const day = now.getDay();
        const weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
        const week = weeks[day];
        const hour = (now.getHours()) < 10 ? '0' + now.getHours() : now.getHours();
        const minutes = (now.getMinutes()) < 10 ? '0' + now.getMinutes() : now.getMinutes();
        switch (this.optionSwitch) {
          case 0:
            data.endRepeat.endRepeatType = 1
            data.endRepeat.title = '一直重复'
            break;
          case 1:
            data.endRepeat.repeatNum = this.repeatValue
            data.endRepeat.endRepeatType = 2
            data.endRepeat.title = '重复' + this.repeatValue + '次'
            break;
          case 2:
            data.endRepeat.endTime = this.overDate
            data.endRepeat.endRepeatType = 3
            data.endRepeat.title = '重复' + this.repeatTime + '结束'

            break;
        }
        switch (this.radio) {
          case '1':
            data.repeat.name = "每日"
            data.repeat.repeatType = 1
            data.repeat.time = hour + ":" + minutes
            data.repeat.title = "每日" + hour + ":" + minutes + "重复"
            break;
          case '2':
            data.repeat.name = "每周"
            data.repeat.repeatType = 2
            data.repeat.week = week
            data.repeat.time = hour + ":" + minutes
            data.repeat.title = "每周" + week + hour + ":" + minutes + "重复"
            break;
          case '3':
            data.repeat.name = "每月"
            data.repeat.day = dd
            data.repeat.week = week
            data.repeat.time = hour + ":" + minutes
            data.repeat.repeatType = 3
            data.repeat.title = "每月" + dd + '号' + hour + ":" + minutes + "重复"

            break;
        }

      } else {
        switch (this.optionSwitch) {
          case 0:
            data.endRepeat.endRepeatType = 1
            data.endRepeat.title = '一直重复'
            break;
          case 1:
            data.endRepeat.repeatNum = this.repeatValue
            data.endRepeat.endRepeatType = 2
            data.endRepeat.title = '重复' + this.repeatValue + '次'
            break;
          case 2:
            data.endRepeat.endTime = this.repeatTime
            data.endRepeat.endRepeatType = 3
            data.endRepeat.title = '重复' + this.repeatTime + '结束'

            break;
        }
        data.repeat = this.customDate
      }
      this.show = false
      this.$emit("change", data)
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },

  }
}
</script>

<style lang="less" scoped>
/deep/ .van-action-sheet__header {
  font-weight: bolder;
}

.component {
  .img-icon {
    height: 20px;
  }

  .content {
    padding: 0;

    .rDatae {
      display: flex;
      flex-wrap: wrap;
      padding: 5px 12px;

      .active {
        background: #1890ff;
        color: #ffffff
      }

      div {
        width: 30px;
        height: 30px;
        /* background: red; */
        /* display: flex; */
        line-height: 30px;
        text-align: center;
        color: #444;
        border-radius: 50%;
        margin: 3px 10px;
      }
    }

    .frequency {
      width: 92px;
      background: #f1f0f0;
      /* text-align: right; */
      margin-right: 0;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: space-between;
      float: right;
      height: 32px;
      padding: 5px;
      border-radius: 5px;

      .active {
        background: #1890ff;
        color: #fff;
      }

      span {
        margin: 1px;
        display: block;
        width: 25px;
        height: 25px;
        text-align: center;
        border-radius: 3px;
      }
    }

    .algin {
      padding: 10px 16px;
    }

    .active-btn {
      color: #1890ff !important;
      background: #f3f9ff !important;
      border: 1px solid #1890ff !important;
    }

    .options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      div {
        color: #84878c;
        background: #fff;
        border-radius: 3px;
        border: 1px solid #dcdee0;
        display: inline-block;
        padding: 5px 12px;
      }
    }

    .user-list {
      height: 275px;
      overflow: auto;

      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 14px;
          color: #252528;

          img {
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }

      .active-background {
        background: #f6fbff !important;
      }
    }
  }
}
</style>
