<template>
  <div class="index">
    <div class="top-part">
      <div class="top-info">
        <div class="time-tips fs24 van-ellipsis">
          <div class="time-tips-box"><span>{{ userDatalt.good }}，{{ userDatalt.name }}</span><!----></div>
        </div>
        <!--        <div class="icon-message">&lt;!&ndash;&ndash;&gt;</div>-->
        <div class="time-detail fs14" style="color: #fff">{{ date }}，{{ week }}</div>
      </div>
      <div class="customer-data">
        <div class="data-item">
          <div class="customer-label"><span class="fs14">我的客户</span></div>
          <div class="customer-value fs24">{{ baseInfo.total }}</div>
        </div>
        <div class="data-item">
          <div class="customer-label"><span class="fs14">昨日新增</span></div>
          <div class="customer-value fs24 center">{{ baseInfo.yesterdayAdd }}</div>
        </div>
        <div class="data-item">
          <div class="customer-label"><span class="fs14">昨日流失</span></div>
          <div class="customer-value fs24 center">{{ baseInfo.yesterdayLoss }}</div>
        </div>
      </div>
    </div>

    <div class="main-content">
      <div style="padding-bottom: 13px;" class="tools-list">
        <div @click="routerPush('/contactStatistic/index')" class="tools-item">
          <div class="img-box"><img
              src="../../assets/images/tongji.png"
              alt=""></div>
          <span class="fs14 tools-name">拉新统计</span></div>
        <div @click="routerPush('/channelCode/index')" class="tools-item">
          <div class="img-box"><img
              src="../../assets/images/huoma.png"
              alt=""></div>
          <span class="fs14 tools-name">渠道活码</span></div>
        <div @click="routerPush('/contactWelcome/index')" class="tools-item">
          <div class="img-box"><img
              src="../../assets/images/huanyingyu.png"
              alt=""></div>
          <span class="fs14 tools-name">欢迎语</span></div>
        <div class="tools-item" @click="routerPush('/workMoment/index')">
          <div class="img-box"><img
              src="../../assets/images/pengyouquan.png"
              alt=""></div>
          <span class="fs14 tools-name">历史朋友圈</span></div>
      </div>
      <div style="padding-bottom: 13px;display: none" class="tab-bar fs14">
        <div class="tab-item active fs16" style="font-size: 16px;font-weight: bold;"> 我的待办(0)</div><!---->
        <div class="check-more"><span class="fs12">更多</span>
          <a-icon type="right"/>
        </div>
      </div><!---->
      <div style="position: relative">
        <div class="select">
          <div class="title" @click="openSelect">
            {{ optionChose }}
            <span v-if="optionChose == '待开始'">({{ todo.wait.length }})</span>
            <span v-if="optionChose == '已完成'">({{ todo.finish.length }})</span>
            <span v-if="optionChose == '已逾期'">({{ todo.overdue.length }})</span>

            <a-icon :class="{ 'iconS': isSelect }" style="color:#DBDBDB;margin-left: 5px" type="caret-up"/>
          </div>
          <div class="button">
            <!--            <div class="qieh">-->
            <!--              <a-icon v-if="switching" @click="switching = false" type="schedule"/>-->
            <!--              <van-icon v-else @click='switching = true' name="qr"/>-->
            <!--            </div>-->
            <van-button style="width: 80px;height: 30px;    border-radius: 25px;
    margin-left: 5px;" @click="routerPush('/index/create')" type="info">新建
            </van-button>
          </div>
        </div>
        <div class="chose" v-if="isSelect">
          <div v-for="item in optionC" @click="selectType(item)">
            <div class="div" v-if="item != optionChose">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="false">
      <van-calendar
          v-if="switching && !isSelect"
          style="margin: 5px 15px"
          :poppable="false"
          row-height="30"
          :show-confirm="false"
          color="#1890ff"
          :show-title="false"
          :min-date="new Date('2000/01/01')"
          :style="{ height: '260px' }"
          @select="dataSelect"
          :formatter="formatter"
          ref="content"
      />
    </div>

    <div v-if="!isLoading">
      <div v-if="optionChose == '待开始'">
        <div v-if="todo.wait.length > 0" class="data-list">
          <div class="list-time" style="font-size: 14px;">
            {{ listDate }}
          </div>
          <van-radio-group v-model="radio" icon-size="14">
            <div v-for="(item, index) in todo.wait" :key="index">
              <div @click="details(item.id)" class="title">
                <div>
                  <img src="../../assets/images/date.png">
                  任务
                </div>
              </div>
              <div class="content">
                <div>
                  <van-radio class="title-overflow" @click="overDate(item.id)" :name="item.id">
                    {{ item.content }}
                  </van-radio>
                </div>
                <div @click="details(item.id)" class="content-user">
                  <div style="display: flex;align-items: center;margin-left: 7%;
    margin-top: 7px;">
                    <img style="margin-right: 8px;" src="../../assets/images/clock.png">
                    {{ item.day }} {{ item.time }}
                  </div>
                </div>
                <div class="content-name">
                  <div class="name-user">
                    <img :src="item.contactAvatar">
                    {{ item.contactName }}
                  </div>
                  <div class="name-button" @click="detailsee(item)">
                    跟进
                    <a-icon type="right"/>
                  </div>
                </div>
              </div>
            </div>
          </van-radio-group>
        </div>
        <div v-else class="data-list" style="background: none">
          <div class="list-time" style="font-size: 14px;margin-bottom: 5px;">
            {{ listDate }}
          </div>
          <div style="background: #fff">
            <van-empty :image="require('../../assets/images/mobiletodo.png')"
                       description="没有待开始的待办~"/>
          </div>
        </div>
      </div>
      <div v-if="optionChose == '已完成'">
        <div v-if="todo.finish.length > 0" class="data-list">
          <div class="list-time" style="font-size: 14px;">
            {{ listDate }}
          </div>
          <van-radio-group v-model="radio" icon-size="14">
            <div v-for="(item, index) in todo.finish" :key="index">
              <div @click="details(item.id)" class="title">
                <div>
                  <img src="../../assets/images/date.png">
                  任务
                </div>
              </div>
              <div class="content hide-icon">
                <div>
                  <van-radio :name="item.id">
                    {{ item.content }}
                  </van-radio>
                </div>
                <div @click="details(item.id)" class="content-user">
                  <div style="display: flex;align-items: center;margin-left: 7%;
    margin-top: 7px;">
                    <img style="margin-right: 8px;" src="../../assets/images/clock.png">
                    {{ item.day }} {{ item.time }}
                  </div>
                </div>
                <div @click="details(item.id)" class="content-name">
                  <div class="name-user">
                    <img :src="item.contactAvatar">
                    {{ item.contactName }}
                  </div>
                  <!--                  <div class="name-button">-->
                  <!--                    跟进-->
                  <!--                    <a-icon type="right"/>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </van-radio-group>
        </div>
        <div v-else class="data-list" style="background: none">
          <div class="list-time" style="font-size: 14px;margin-bottom: 5px;">
            {{ listDate }}
          </div>
          <div style="background: #fff">
            <van-empty :image="require('../../assets/images/mobiletodo.png')"
                       description="没有已完成的待办~"/>
          </div>
        </div>
      </div>
      <div v-if="optionChose == '已逾期'">
        <div v-if="todo.overdue.length > 0" class="data-list">
          <div class="list-time" style="font-size: 14px;">
            {{ listDate }}
          </div>
          <van-radio-group v-model="radio" icon-size="14">
            <div v-for="(item, index) in todo.overdue" :key="index">
              <div @click="details(item.id)" class="title">
                <div>
                  <img src="../../assets/images/date.png">
                  任务
                </div>
              </div>
              <div class="content">
                <div>
                  <van-radio @click="overDate(item.id)" class="title-overflow" :name="item.id">
                    <div class="tips">逾期</div>
                    {{ item.content }}
                  </van-radio>
                </div>
                <div @click="details(item.id)" class="content-user">
                  <div style="display: flex;align-items: center;margin-left: 7%;
    margin-top: 7px;">
                    <img style="margin-right: 8px;" src="../../assets/images/clock.png">
                    {{ item.day }} {{ item.time }}
                  </div>
                  <div v-if="item.overdueDays">已逾期<span style="color: #ee5855">{{ item.overdueDays }}</span>天</div>
                  <div v-else>已逾期<span style="color: #ee5855">{{ item.overdueHours }}</span>小时</div>
                </div>
                <div @click="details(item.id)" class="content-name">
                  <div class="name-user">
                    <img :src="item.contactAvatar">
                    {{ item.contactName }}
                  </div>
                  <div class="name-button">
                    跟进
                    <a-icon type="right"/>
                  </div>
                </div>
              </div>
            </div>
          </van-radio-group>
        </div>
        <div v-else class="data-list" style="background: none">
          <div class="list-time" style="font-size: 14px;margin-bottom: 5px;">
            {{ listDate }}
          </div>
          <div style="background: #fff">
            <van-empty :image="require('../../assets/images/mobiletodo.png')"

                       description="没有已逾期的待办~"/>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-else>
      <a-spin/>
    </div>

    <van-tabbar v-model="active" :safe-area-inset-bottom="true" class="tab">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/home.png"/>
          <img v-else src="../../assets/images/home-active.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/contact/index" icon="search">
        <span>客户</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/customer-active.png"/>
          <img v-else src="../../assets/images/customer.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/tool/index" icon="setting-o">
        <span>运营工具</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/function-active.png"/>
          <img v-else src="../../assets/images/function.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="setting-o">
        <span>我的</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/my-active.png"/>
          <img v-else src="../../assets/images/my.png">
        </template>
      </van-tabbar-item>
    </van-tabbar>

  </div>
</template>

<script>
import {workEmployee, updateStatus, userCenter} from "../../api/homePage"

export default {
  data() {
    return {
      schedule: 'have-matter-wait',
      // 默认日期
      week: '',
      date: '',
      // 列表展示日期
      listDate: '',
      // 数据类别加载动画
      isLoading: true,
      // tab 切换
      active: 0,
      // 单选
      radio: true,
      // 下拉分类
      optionC: ["已逾期", '已完成', '待开始'],
      isSelect: false,
      optionChose: '待开始',
      switching: true,
      // 顶部预览数据
      baseInfo: '',
      todo: {
        wait: [],
        finish: [],
        overdue: []
      },
      userDatalt: {
        name: '',
        good: ''
      }
    }
  },
  watch: {},
  methods: {
    activeTab(e) {
      this.active = e
    },
    openSelect() {
      this.isSelect = !this.isSelect
    },
    selectType(e) {
      this.isSelect = false
      this.optionChose = e
      switch (e) {
        case '已逾期':
          this.schedule = 'have-matter-over'
          break;
        case '已完成':
          this.schedule = 'have-matter-finsh'
          break;
        case '待开始':
          this.schedule = 'have-matter-wait'
          break;
      }

      // this.$refs.content.formatter.haveMatter()
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
    // 获取数据
    getNowTime(e) {
      this.isLoading = true
      workEmployee({day: e}).then(res => {
        this.baseInfo = res.data.baseInfo
        this.todo = res.data.todo
        this.isLoading = false
      })
    },
    // 获取当前日期
    getWeekDate() {
      const now = new Date();
      const day = now.getDay();
      const weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
      const year = new Date().getFullYear();
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      this.week = weeks[day];
      this.date = mm + "月" + dd + "日"
      this.dateDetail = {
        year: year,
        month: mm,
        day: dd
      }
      this.listDate = '今天 · ' + mm + "月" + dd + "日" + "  " + weeks[day]

    },
    // 根据日期切换
    dataSelect(e) {
      const year = e.getFullYear();
      const month = (e.getMonth() + 1) < 10 ? '0' + (e.getMonth() + 1) : (e.getMonth() + 1);    //js从0开始取
      const date1 = (e.getDate()) < 10 ? '0' + (e.getDate()) : (e.getDate());
      const day = e.getDay();
      const weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
      if (date1 == this.dateDetail.day) {
        this.listDate = '今天 · ' + month + "月" + date1 + "日" + "   " + weeks[day]
      } else if (year == this.dateDetail.year) {
        this.listDate = month + "月" + date1 + "日" + "   " + weeks[day]
      } else {
        this.listDate = year + '年' + month + "月" + date1 + "日" + "   " + weeks[day]
      }
      this.overTextDate = year + "-" + month + "-" + date1
      this.getNowTime(year + "-" + month + "-" + date1)
    },
    // 日期格式化
    formatter(day, css) {
      const year = day.date.getFullYear();
      const month = day.date.getMonth() + 1;
      const date = day.date.getDate();
      ('日期格式化')
      const details = this.dateDetail

      if (year === details.year && month === details.month) {
        if (date === 1) {
          day.className = this.schedule;
        } else if (date === 4) {
          day.className = this.schedule;
        } else if (date === details.day) {
          day.className = 'nowDate';
        }
      }


      return day;
    },
    // 完成任务
    overDate(id) {
      updateStatus({id: id}).then(res => {
        this.getNowTime(this.overTextDate)
      })
    },
    details(id) {
      this.$router.push({
        path: '/index/create',
        query: {
          id: id
        }
      })
    },
    userDetails() {

      userCenter().then(res => {
        this.userDatalt = res.data.baseInfo
        const now = new Date()
        const hour = now.getHours()

        if (hour < 6) {
          this.userDatalt.good = "凌晨好"
        } else if (hour < 9) {
          this.userDatalt.good = "早上好"
        } else if (hour < 12) {
          this.userDatalt.good = "上午好"
        } else if (hour < 14) {
          this.userDatalt.good = "中午好"
        } else if (hour < 17) {
          this.userDatalt.good = "下午好"
        } else if (hour < 19) {
          this.userDatalt.good = "傍晚好"
        } else if (hour < 22) {
          this.userDatalt.good = "晚上好"
        } else {
          this.userDatalt.good = "夜里好"
        }
      })
    },
    detailsee(i) {
      this.$router.push({
        path: "/contact/detail",
        query: {
          contactId: i.contactId
        }
      })
    }
  },
  created() {
    // 获取日期
    this.getWeekDate()
    // 获取数据
    this.getNowTime()
    // user信息
    this.userDetails()
  }

}
</script>

<style lang="less" scoped>
@wait: #45c400;
@over: #c8cad0;
@finsh: red;
/deep/.van-tabbar{
  box-sizing: content-box !important;
}
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .have-matter-wait {
  &:before {
    background: @wait;
    position: absolute;
    z-index: 88;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 1px;
    //background: #c8cad0;
  }
}

/deep/ .have-matter-over {
  &:before {
    background: @finsh;
    position: absolute;
    z-index: 88;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 1px;
    //background: #c8cad0;
  }
}

/deep/ .have-matter-finsh {
  &:before {
    background: @over;
    position: absolute;
    z-index: 88;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 1px;
    //background: #c8cad0;
  }
}

/deep/ .hide-icon .van-radio__icon {
  visibility: hidden;
}

/deep/ .hide-icon .van-radio__label {
  width: 126px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.van-radio {
  width: 139px;
}


.title-overflow {
  width: 100%;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  margin-top: 3px;
  /deep/ .van-radio__label{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.index {
  font-size: 14px;
  min-height: 100%;
  background-color: #f2f3f7;
  padding-bottom: 70px;

  .fs14 {
    opacity: .7;
    font-weight: 400;
    font-size: 14px;
  }

  .fs16 {
    font-size: 12px;
  }

  .fs12 {
    font-size: 12px;
  }

  .fs24 {
    font-size: 24px;
  }

  .tab {
    img {
      width: 22px;
      height: 22px;
    }
  }

  .iconS {
    transform: rotate(180deg);
    color: #1890ff !important;
  }

  .top-part {
    height: 310px;
    background: linear-gradient(180deg, #3974c7, #0a42c3 55%, #2b63cd);
    padding: 16px 12px 0;
    position: relative;

    .top-info {
      padding: 0 12px 0 8px;

      .time-tips-box {
        display: flex;
        align-items: center;
      }

      .time-tips {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 33px;
        font-weight: 500;
        color: #fff;
        width: 252.5px;
      }

      .van-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .icon-message {
        position: absolute;
        top: 25px;
        right: 20px;
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url(../../assets/images/news.png) no-repeat;
        background-size: 100% 100%;
      }

      .time-detail {
        color: #c2d7ff;
        margin-top: 4px;
        line-height: 20px;
      }
    }

    .customer-data {
      cursor: pointer;
      height: 104px;
      background: url(../../assets/images/background.png) no-repeat 50%/contain;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      padding: 17px 30px 29px 30px;
      position: relative;
      top: 24px;
      color: #fff !important;

      .data-item {
        text-align: center;

        .customer-label {
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 20px;
          position: relative;
        }

        .customer-value {
          font-family: DINAlternateBold;
          font-weight: 700;
          color: #fff;
          line-height: 36px;
        }
      }
    }
  }

  .loading {
    width: 100%;
    height: 228.94px;
    /* background: #fff; */
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .main-content {
    background-color: #f2f3f7;
    margin-top: -131px;
    z-index: 1;
    position: relative;
    border-radius: 4px 4px 0 0;

    .tools-list {
      display: flex;
      flex-direction: row;
      padding-top: 32px;

      .tools-item {
        flex: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img-box {
          width: 36px;
          height: 36px;
          margin-bottom: 8px;

          img {
            width: 36px;
            height: 36px;
          }
        }

        .tools-name {
          color: #545454;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .tab-bar {
      padding: 0 12px;
      margin-top: 36px;
      display: flex;
      position: relative;
      align-items: center;

      .tab-item {
        color: #000;
        position: relative;
        line-height: 24px;
        font-weight: 500;
        cursor: pointer;
        padding: 0 4px;
        margin-right: 30px;
      }

      .active {
        color: #000;
        position: relative;
        line-height: .48rem;
        font-weight: 500;
        cursor: pointer;
        padding: 0 0.08rem;
        margin-right: 0.6rem;

        &:after {
          content: "";
          position: absolute;
          bottom: -11px;
          left: 0;
          width: 100%;
          height: 6px;
          background: rgba(87, 167, 255, .21);
        }
      }

      .check-more {
        cursor: pointer;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        color: rgb(189, 189, 193);
        color: #8c8c8c;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* padding: 0 14px; */
      background: #fff;
      margin: 0 14px;
      padding: 0 7px;
      height: 41px;

      .title {
        font-size: 14px;
        font-weight: bold;
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .qieh {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f2f3f7;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }

    .chose {
      margin: 0 14px;
      background: #fff;
      border-radius: 0px 0px 16px 16px;
      transition: 0.5s;

      .div {
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 8px;

        &:hover {
          color: #1890ff;
          background: #f6fbff;
        }
      }
    }
  }

  .data-list {
    margin: 7px 16px;
    color: #767676;
    font-weight: 500;
    //background-color: #fff;

    .title {
      background: #f4f7ff;
      display: flex;
      align-items: center;
      padding-left: 7px;
      font-size: 14px;
      /* color: #fff; */
      padding: 7px 10px;
      margin-top: 5px;

      div {
        display: flex;
        align-items: center;
      }

      img {
        width: 16px;
        margin-right: 5px;
      }
    }

    .content {
      background: #fff;
      min-height: 80px;
      padding: 7px 10px;

      .tips {
        display: inline-block;
        background: #ee5855;
        color: #fff;
        padding: 1px 3px;
        border-radius: 4px;
        margin-right: 5px;
      }

      .content-user {
        color: #949494;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 12px;
        }
      }

      .content-name {
        background: #f9fafe;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 10px;
        height: 30px;
        margin-top: 10px;

        .name-user {
          display: flex;
          align-items: center;
          font-size: 14px;

          img {
            width: 16px;
            margin-right: 5px;
            border-radius: 50%;
          }

        }

        .name-button {
          font-size: 14px;
          color: #1890ff;
        }
      }
    }
  }
}
</style>
